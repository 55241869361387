import React from "react"
import loadableVisibility from "react-loadable-visibility/loadable-components"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import HomeBanner from "../components/homepage/HomeBanner"
import HomeWelcomeSection from "../components/homepage/HomeWelcomeSection"
import HomeReasons from "../components/homepage/HomeReasons"
import HomeCaseResults from "../components/homepage/HomeCaseResults"
import HomeAccidents from "../components/homepage/HomeAccidents"
import HomeFreeConsultation from "../components/homepage/HomeFreeConsultation"
import favicon from "../images/favicon.png"
import { data } from "cheerio/lib/api/attributes"

const HomePracticeAreas = loadableVisibility(
  () => import(`../components/homepage/HomePracticeAreas`),
  { fallback: <div>Loading... (rendered from the server)</div> }
)
const HomeFeaturedAccolades = loadableVisibility(
  () => import(`../components/homepage/HomeFeaturedAccolades`),
  { fallback: <div>Loading... (rendered from the server)</div> }
)
const WhatOurClientsAreSaying = loadableVisibility(
  () => import(`../components/WhatOurClientsAreSaying`),
  { fallback: <div>Loading... (rendered from the server)</div> }
)
const HomeMeetTheTeam = loadableVisibility(
  () => import(`../components/homepage/HomeMeetTheTeam`),
  { fallback: <div>Loading... (rendered from the server)</div> }
)
const HomeMultilingualSupport = loadableVisibility(
  () => import(`../components/homepage/HomeMultilingualSupport`),
  { fallback: <div>Loading... (rendered from the server)</div> }
)
const HomeBlog = loadableVisibility(
  () => import(`../components/homepage/HomeBlog`),
  { fallback: <div>Loading... (rendered from the server)</div> }
)
const Maps = loadableVisibility(() => import(`../components/Maps`), {
  fallback: <div>Loading... (rendered from the server)</div>,
})

const PageTemplate = ({}) => {
  const INDEX_QUERY = graphql`
    query INDEX_QUERY {
      wpgraphql {
        page(id: "cGFnZToyNg==") {
          title
          seo {
            title
            metaDesc
            opengraphImage {
              mediaItemUrl
            }
            opengraphTitle
            twitterDescription
            twitterImage {
              mediaItemUrl
            }
            twitterTitle
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={INDEX_QUERY}
      render={props => (
        <Layout>
          <Helmet>
            <link rel="icon" href={favicon} />
            <title>
              {props.wpgraphql.page.seo.opengraphTitle
                ? props.wpgraphql.page.seo.opengraphTitle
                : props.wpgraphql.page.seo.title}
            </title>
            <meta
              name="description"
              content={
                props.wpgraphql.page.seo.metaDesc
                  ? props.wpgraphql.page.seo.metaDesc
                  : ""
              }
            />
            {props.wpgraphql.page.seo.opengraphImage !== null && (
              <meta
                name="image"
                content={props.wpgraphql.page.seo.opengraphImage.mediaItemUrl}
              />
            )}
            <meta property="og:url" content={`https://www.mmklawfirm.com/`} />
            <meta
              property="og:title"
              content={
                props.wpgraphql.page.seo.opengraphTitle
                  ? props.wpgraphql.page.seo.opengraphTitle
                  : props.wpgraphql.page.seo.title
              }
            />
            <meta
              property="og:description"
              content={
                props.wpgraphql.page.seo.metaDesc
                  ? props.wpgraphql.page.seo.metaDesc
                  : ""
              }
            />
            {props.wpgraphql.page.seo.opengraphImage !== null && (
              <meta
                property="og:image"
                content={props.wpgraphql.page.seo.opengraphImage.mediaItemUrl}
              />
            )}
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:url"
              content={`https://www.mmklawfirm.com/`}
            />
            <meta
              name="twitter:title"
              content={
                props.wpgraphql.page.seo.twitterTitle
                  ? props.wpgraphql.page.seo.twitterTitle
                  : props.wpgraphql.page.seo.opengraphTitle
                  ? props.wpgraphql.page.seo.opengraphTitle
                  : props.wpgraphql.page.seo.title
              }
            />
            <meta
              name="twitter:description"
              content={
                props.wpgraphql.page.seo.twitterDescription
                  ? props.wpgraphql.page.seo.twitterDescription
                  : props.wpgraphql.page.seo.metaDesc
                  ? props.wpgraphql.page.seo.metaDesc
                  : ""
              }
            />
            {props.wpgraphql.page.seo.opengraphImage != null ||
              (props.wpgraphql.page.seo.twitterImage != null && (
                <meta
                  name="twitter:image"
                  content={
                    props.wpgraphql.page.seo.twitterImage
                      ? props.wpgraphql.page.seo.twitterImage.mediaItemUrl
                      : props.wpgraphql.page.seo.opengraphImageimageFile
                      ? props.wpgraphql.page.seo.opengraphImageimageFile
                          .mediaItemUrl
                      : ""
                  }
                />
              ))}
          </Helmet>
          <HomeBanner title={props.wpgraphql.page.title} />
          <HomeWelcomeSection />
          <HomePracticeAreas />
          <HomeReasons />
          <HomeFeaturedAccolades />
          <HomeCaseResults />
          <WhatOurClientsAreSaying />
          <HomeMeetTheTeam />
          <HomeMultilingualSupport />
          <HomeAccidents />
          <HomeBlog />
          <HomeFreeConsultation />
          <Maps />
        </Layout>
      )}
    />
  )
}

export default PageTemplate
