import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import section_2bg from '../../images/section_2bg.png'
import styled from "@emotion/styled"
import Img from 'gatsby-image'
import FsLightbox from 'fslightbox-react';
import BackgroundImage from "gatsby-background-image"

const WelcomeSectionContainer = styled.div`
margin: 0 auto;
padding: 0 15px;
    padding-bottom: 200px;
.gatsby-image-wrapper{
    z-index: 2;
}
.row {
    display: flex;
    flex-wrap: wrap;
}
.video_bg_red{
    position: relative;
    @media(min-width: 992px){
        padding-right: 15px;
    }
    
}
.video_bg_red img{
    width: 100%;
    height: 100%;
}
.vidio_icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 3;
}
.bg_abs {
    right: 0;
    position: absolute !important;
    bottom: -76px;
    width: 100%;
    height: 300px;
    
}
.video_bg_red .bg_abs span{
    bottom: 26px;
    position: absolute;
    left: 0;
    width: 100%;
    color: #fff;
    padding-left: 16px;
}
 
    .col-lg-9 {
        flex: 0 0 100%;
    }
    
  
  
    
@media(min-width: 576px){
    max-width: 540px;
}
@media(min-width: 768px){
    position: relative;
    padding-top: 200px;
}
@media(min-width: 992px){
    display: grid;
    grid-template-columns: 33% 59%;
    grid-column-gap: 8%;
    padding: 0 0 0 15px;
    max-width: 960px;
    align-items: center;
    padding: 280px 0 100px
}
@media(min-width: 1200px){
    max-width: 1300px;
}
@media(min-width: 1367px){
    padding: 200px 0;
}
@media(min-width: 1920px){
    max-width: 1600px;
}
`
const WelcomeSectionContainerContainer = styled.div`
background-color: #0e1f38;
@media(min-width: 768px){
    background-image: url(${section_2bg});
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 100%;
    background-position: 50%;
    background-attachment: fixed;
}
@media(min-width: 992px){
    position: relative;
    background: linear-gradient(to bottom, rgba(17,34,58,1) 0%,rgba(17,34,58,1) 5%,rgba(17,34,58,0) 20%), url(${section_2bg});
    background-attachment: scroll, fixed;
}
`
const WelcomeSectionContainerContainerContainer = styled.div`
@media(min-width: 992px){
    //background: ;
}
`
const StyledH1 = styled.h1`
margin-block-start: 0;
padding-top: 1em;
color: #707783;
    font-size: 16px;
    line-height: 24px;
`
const ParagraphContainer = styled.div`
color: #fff;
line-height: 26px;
clear: both;
margin-bottom: 2em;
`
const DiamondLeft = styled(Img)`
width: 200px;
    float: right;
    margin-bottom: 10px;
    @media(min-width: 576px){
        width: 82%;
        float: none;
        margin-bottom: 40px;
    }
    @media(min-width: 992px){
    right: -64%;
    z-index: 3 !important;
    width: 100%;
    }
`
const TextContainer = styled.div`
`
const VideoContainer = styled.div`
@media(min-width: 992px){
    position: absolute;
        width: 50%;
        right: 0;
       top: 50%;
       transform: translateY(-50%);
   
    
}
`
const HomeWelcomeSection = ({}) => {
    const [toggler, setToggler] = useState(false);
    const customSources = [
        <iframe src="https://player.vimeo.com/video/367865811?autoplay=1" width="1920px" height="1080px"
        frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
        ]
    const HomeWelcomeSection_QUERY =graphql`
    query {
      wpgraphql {
        page(id: "cGFnZToyNg==") {
            Homepage {
                welcomeSectionH1LineOne
              welcomeSectionH1LineTwo
              welcomeSectionH3
              welcomeSectionParagraph
            }
        }
    }
    diamondLeft: file(relativePath: {eq: "diamond-left.png"}) {
        childImageSharp {
          fluid(maxWidth: 566) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
    video2: file(relativePath: {eq: "video1_img.png"}) {
        childImageSharp {
          fluid(maxWidth: 755, maxHeight: 500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      videoBtn: file(relativePath: {eq: "video_btn.png"}) {
        childImageSharp {
          fixed(width: 135, height: 135) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      videoImgBg: file(relativePath: {eq: "video_img_bg.png"}) {
        childImageSharp {
          fluid(maxWidth: 755, maxHeight: 300) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
}
`
return (
    <StaticQuery query={HomeWelcomeSection_QUERY} render={props => (
        <WelcomeSectionContainerContainerContainer>
        <WelcomeSectionContainerContainer>
        <WelcomeSectionContainer>
            <TextContainer>
            <StyledH1>
                {props.wpgraphql.page.Homepage.welcomeSectionH1LineOne}<br />
                {props.wpgraphql.page.Homepage.welcomeSectionH1LineTwo}
            </StyledH1>
            <h3>
            {props.wpgraphql.page.Homepage.welcomeSectionH3}
            </h3>
            <DiamondLeft fluid={props.diamondLeft.childImageSharp.fluid} />
                <ParagraphContainer dangerouslySetInnerHTML={{__html: props.wpgraphql.page.Homepage.welcomeSectionParagraph}} />
                </TextContainer>
                <VideoContainer>
                <div className="sc-bwzfXH row uTxCW">
    <div className="col-12 col-lg-9">
        <div className="sc-jwKygS video_bg_red fBWxJW">
            <a  onClick={() => setToggler(!toggler)}>
                <Img fluid={props.video2.childImageSharp.fluid} loading="lazy"/>
                    <div className="vidio_icon">
                    <Img fixed={props.videoBtn.childImageSharp.fixed} loading="lazy"/>
                            </div>
                            
                            <BackgroundImage fluid={props.videoImgBg.childImageSharp.fluid} className="bg_abs" loading="lazy">
                                    <span>GET JUSTICE FOR YOUR PAIN AND SUFFERING</span>
                                    </BackgroundImage>
                                    
                                    </a>
                                    </div>
                                    </div>
                                    </div>

            </VideoContainer>
        </WelcomeSectionContainer>
        </WelcomeSectionContainerContainer>
        <FsLightbox
toggler={toggler}
sources={customSources}
/>
        </WelcomeSectionContainerContainerContainer>
    )} />
)
}
export default HomeWelcomeSection