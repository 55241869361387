import React, { useState, useEffect, useRef } from 'react'
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import Img from 'gatsby-image'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BackgroundImage from 'gatsby-background-image'
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const AccidentsContainer = styled(BackgroundImage)`
padding: 50px 15px 20px 15px;
margin-top: -26px;

@media(min-width: 576){
    margin-top: -30px;
    
}
@media(min-width: 768){
    margin-top: -50px;

}
`
const AccidentsContainerInner = styled.div`
padding: 50px 15px 20px 15px;
@media(min-width: 576px){
    max-width: 540px;
    margin: 0 auto;
}
@media(min-width: 768px){
    max-width: 720px;
}
@media(min-width: 992px){
    max-width: 960px;
    display: grid;
    grid-template-columns: 42% 58%;
    grid-column-gap: 30px;
    align-items: center;
}
@media(min-width: 1200px){
    max-width: 1300px;
}
@media(min-width: 1920px){
    max-width: 1600px;
}
`
const DiamondRight = styled(Img)`
    right: auto;
    margin: 20px 0 25px 0;
`
const AccidentsSliderTop = styled(Slider)`
${DiamondRight} img[style]{
    object-position: right center !important;
}
`
const TopSlideContainer = styled.div`

`
const StyledH4 = styled.h4`
letter-spacing: 2px;
font-size: 38px;
color: #f1d1a2;
font-family: "Playfair Display";
font-weight: 500;
line-height: 1.2;

@media(min-width: 992px){
    font-size: 60px;
}
`
const StyledSpan = styled.span`
color: #707783;
margin-top: 12px;
display: inline-block;
font-weight: 600;
`

const AccidentsContent = styled.div`
color: #d4d7da;
font-size: 16px;
line-height: 27px;
`
const AccidentsSliderBottom = styled(Slider)`

@media(min-width: 768px){
    .slick-track[style]{
        width: 100% !important;
    }
    .slick-slide[style]{
        width: 33% !important;
    }
}
`
const AccidentsTextOverImage = styled.span`
position: absolute;
    left: 50%;
    text-align: center;
    top: 85%;
    transform: translate(-50%,-85%);
    color: #fff;
    font-size: 16px;
    transition: all .4s ease-in-out;
    margin-top: 12px;
    display: inline-block;
    font-weight: 600;
`
const AccidentRedBackground = styled.div`
position: absolute;
top: 0;
left: 0;
right: 15px;
bottom: 15px;
`
const BottomSlideContainer = styled.div`
position: relative;

:hover{
   
    ${AccidentsTextOverImage}{
        top: 50%;
        transform: translate(-50%, -50%);
    }
    ${AccidentRedBackground}{
        background-color: rgba(182,33,42,.8);
    }
    @media(min-width: 768px){
        cursor: pointer;
    }
}
`
const AccidentImage = styled(Img)`
margin: 0 15px 15px 0;
`

const HomeAccidents = () =>{
    const breakpoints = useBreakpoint();
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    
    const slider1 = useRef(null);
    const slider2 = useRef(null);
    

    useEffect(() => {
        setNav1(slider1.current);
        setNav2(slider2.current);
      }, []);
      const settings = {
        arrows: false,
                dots: false,
                fade: true,
      }
      const settings2 = {
        focusOnSelect: true,
        slidesToShow: 3,
        slidesToScroll:1,
       centerMode:true,
        arrows: false,
                dots: false,
      }
      const settings3 = {
        arrows: false,
                dots: false,
      }
      const settings4 = {
        slidesToShow: 1,
        slidesToScroll:1,
       centerMode:true,
        arrows: false,
                dots: false,
      }
      return(
        <StaticQuery
        query={graphql`
{
    wpgraphql {
        page(id: "cGFnZToyNg==") {
            Homepage {
                accidentsSlides {
                  accidentsSlidesH4
                  accidentsSlidesSubheading
                  accidentsSlidesParagraph
                  accidentsSlidesImage {
                    sourceUrl
                    imageFile {
                      childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  accidentsSlidesTextOverImage
                }
              }
        }
    }
    backgroundImage: file(relativePath: {eq: "support_3.png"}) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
    diamondRight: file(relativePath: {eq: "diamond-right.png"}) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
}
`
}
render={props => (
    <>
    {breakpoints.xs || breakpoints.sm ?
    <AccidentsContainer fluid={props.backgroundImage.childImageSharp.fluid} critical>
        <AccidentsContainerInner>
            
<AccidentsSliderTop 
    ref={slider1} 
      {...settings3}
      asNavFor={nav2}
      >
          {props.wpgraphql.page.Homepage.accidentsSlides.map(slide => {
    return (
        <TopSlideContainer>
        <StyledH4>
            {slide.accidentsSlidesH4}
        </StyledH4>
        <StyledSpan>
            {slide.accidentsSlidesSubheading}
        </StyledSpan>
        <DiamondRight fluid={props.diamondRight.childImageSharp.fluid}/>
        <AccidentsContent dangerouslySetInnerHTML={{__html: slide.accidentsSlidesParagraph}}/>
        </TopSlideContainer>
    )
          }
          )
        }

</AccidentsSliderTop>
<AccidentsSliderBottom ref={slider2}
        {...settings4} asNavFor={nav1}>
{props.wpgraphql.page.Homepage.accidentsSlides.map(slide2 => {
    return (
        <BottomSlideContainer>
        <AccidentImage fluid={slide2.accidentsSlidesImage.imageFile.childImageSharp.fluid}/>
        <AccidentRedBackground>
        <AccidentsTextOverImage dangerouslySetInnerHTML={{__html: slide2.accidentsSlidesTextOverImage}}/>
        </AccidentRedBackground>
        </BottomSlideContainer>
    )
          }
          )
        }
</AccidentsSliderBottom>
</AccidentsContainerInner>
    </AccidentsContainer>
    : 
    <AccidentsContainer fluid={props.backgroundImage.childImageSharp.fluid} critical>
        <AccidentsContainerInner>
            
<AccidentsSliderTop 
    ref={slider1} 
      {...settings}
      asNavFor={nav2}
      >
          {props.wpgraphql.page.Homepage.accidentsSlides.map(slide => {
    return (
        <TopSlideContainer>
        <StyledH4>
            {slide.accidentsSlidesH4}
        </StyledH4>
        <StyledSpan>
            {slide.accidentsSlidesSubheading}
        </StyledSpan>
        <DiamondRight fluid={props.diamondRight.childImageSharp.fluid}/>
        <AccidentsContent dangerouslySetInnerHTML={{__html: slide.accidentsSlidesParagraph}}/>
        </TopSlideContainer>
    )
          }
          )
        }

</AccidentsSliderTop>
<AccidentsSliderBottom ref={slider2}
        {...settings2} asNavFor={nav1}>
{props.wpgraphql.page.Homepage.accidentsSlides.map(slide2 => {
    return (
        <BottomSlideContainer>
        <AccidentImage fluid={slide2.accidentsSlidesImage.imageFile.childImageSharp.fluid}/>
        <AccidentRedBackground>
        <AccidentsTextOverImage dangerouslySetInnerHTML={{__html: slide2.accidentsSlidesTextOverImage}}/>
        </AccidentRedBackground>
        </BottomSlideContainer>
    )
          }
          )
        }
</AccidentsSliderBottom>
</AccidentsContainerInner>
    </AccidentsContainer>
    }
    </>
)
}
/>
)
}
export default HomeAccidents